import React from "react";
import styled from "styled-components";
import Aasim from "../images/aasim.jpeg";
import Imran from "../images/ImranAhmedPicture.jpeg";
import Umair from "../images/umair.jpeg";

const imageContent = [
  { name: "IMRAN AHMED", position: "Chairman", image: Imran },
  { name: "AASIM KHAN", position: "Treasurer", image: Aasim },
  { name: "UMAIR FAROOQ", position: "Secretary", image: Umair },
];

const AboutContent = () => {
  return (
    <Container>
      <div
        style={{
          marginTop: "10px",
        }}>
        <Title>
          Asalamualykum warahmatullah he wabaraktuhu (peace and blessings be
          upon you)
        </Title>
        <Content>
          Thank you for visiting Madina Masjid (Mosque). We are located on
          Victoria Street in Darwen and were the first Masjid in Darwen; we
          first opened in 1986. A mosque is a place for worship but it is also a
          hub for the community, all faiths and all people are welcome. Why
          don’t you pop by for a cup of tea, a chat, or any questions you may
          have? It would be great for you to come and meet some of the diverse
          people we have within our community.
        </Content>
        <Grid>
          {imageContent.map((item, key) => (
            <Image
              key={key}
              name={item.name}
              image={item.image}
              position={item.position}
            />
          ))}
        </Grid>
      </div>
    </Container>
  );
};

export default AboutContent;

const Image = ({ name, image, position }) => {
  return (
    <NewsContainer>
      <img src={image} width="300px" height="300px" />
      <ImageTitle>{name}</ImageTitle>
      <SubTitle>{position}</SubTitle>
    </NewsContainer>
  );
};

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
`;

const ImageTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  background-color: #fff;
  padding: 4% 12%;
`;

const Title = styled.h3`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Content = styled.p`
  marginbottom: 20px;
  font-size: 18px;
`;
